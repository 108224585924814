import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from 'src';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { setMe } from '../../app/setMe/setMe.slice';
import { Errors } from '../../errors';
import getUserInfoRequest from '../../user/getUserInfo/getUserInfo.repository';
import cancelSubRequest from './cancelSub.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const cancelSub = createAsyncThunk(
  'cancelSub',
  async (userSubId: number, { dispatch }) => {
    await cancelSubRequest(userSubId);
    const userInfo = await getUserInfoRequest();
    dispatch(setMe(userInfo));
    queryClient.invalidateQueries({ queryKey: ['userInfo'] });
    return;
  }
);

const cancelSubSlice = createSlice({
  name: 'cancelSub',
  initialState,
  reducers: {
    cancelSubReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cancelSub.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      cancelSub.fulfilled,
      (state, action: PayloadAction<void>) => {
        state.loading = false;
        state.data = true;
      }
    );
    builder.addCase(cancelSub.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default cancelSubSlice.reducer;
export const { cancelSubReset } = cancelSubSlice.actions;
