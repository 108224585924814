import { useNavigate } from 'react-router-dom';
import subscriptionCard from 'src/assets/images/home/subscription-card.png';
import FirebaseKey from 'src/components/FirebaseKey/FirebaseKey';
import {
  FirebaseEvents,
  useFirebaseEvent,
} from 'src/core/services/firebase/eventHooks';
import FirebaseService from 'src/core/services/firebase/firebaseService';
import { useUserInfo } from 'src/core/services/user/userApi';
import { CustomButton } from '../Buttons/CustomButton';

export function UpgradeBanner() {
  const navigate = useNavigate();
  const sendPurchaseEvent = useFirebaseEvent(
    FirebaseEvents.homepage_purchase_user_count
  );
  const { data: userInfoData } = useUserInfo();
  const activeSubs = userInfoData?.activeSubs;
  const activeSub =
    activeSubs && activeSubs.length > 0 ? activeSubs[0] : undefined;
  // TOBEREMOVED
  // const cancelled = activeSub?.cancelledOn;
  // TOBEREMOVED
  // const isUpgradable = activeSub?.isUpgradable;
  // TOBEREMOVED
  // const isCampaign = !!activeSub?.campaignName;
  // TOBEREMOVED
  // const showUpgradeBanner = activeSub && !cancelled && isUpgradable;
  // const isUpgradable = false;
  // TOBEREMOVED
  if (
    activeSub
    //&& !showUpgradeBanner
  ) {
    return null;
  }
  // TOBEREMOVED
  // if (isCampaign) {
  //   return null;
  // }

  return (
    <div className="relative flex gap-1 overflow-hidden rounded-md bg-[#002C40] p-4">
      <img
        src={subscriptionCard}
        className="absolute inset-0 h-full w-full object-cover object-center"
        alt=""
      />
      <div className="z-10 flex w-32 grow flex-col gap-1">
        <div className="break-word text-white">
          {/* {isUpgradable ? (
            <FirebaseKey
              firebaseKey={FirebaseService.hamburger_upgrade_header}
            />
          ) : ( */}
          <FirebaseKey
            firebaseKey={FirebaseService.hamburger_abonelik_abone_header}
          />
          {/* )} */}
        </div>
        <div className="flex break-word text-white/50">
          {/* {isUpgradable ? (
            <FirebaseKey firebaseKey={FirebaseService.hamburger_upgrade_desc} />
          ) : ( */}
          <FirebaseKey
            firebaseKey={FirebaseService.hamburger_abonelik_aboneolmayan_desc}
          />
          {/* )} */}
        </div>
      </div>
      <div className="z-10 flex w-auto grow items-end">
        <CustomButton
          variant={'lightblue'}
          size={'default'}
          onClick={() => {
            sendPurchaseEvent();
            navigate('/subscription');
          }}
          className="overflow-hidden rounded-md"
        >
          {/* {isUpgradable ? (
            <FirebaseKey
              firebaseKey={FirebaseService.hamburger_upgrade_buton}
            />
          ) : ( */}
          <FirebaseKey
            firebaseKey={FirebaseService.hamburger_abonelik_aboneolmayan_buton}
          />
          {/* )} */}
        </CustomButton>
      </div>
    </div>
  );
}
