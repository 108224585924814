import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from 'src';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { UserInfoRequest } from '../../../models/requests/userInfo.request';
import { setMe } from '../../app/setMe/setMe.slice';
import { Errors } from '../../errors';
import getUserInfoRequest from '../getUserInfo/getUserInfo.repository';
import updateUserInfoRequest from './updateUserInfo.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const updateUserInfo = createAsyncThunk(
  'updateUserInfo',
  async (request: UserInfoRequest, { dispatch }) => {
    const response = await updateUserInfoRequest(request);
    const userInfo = await getUserInfoRequest();
    dispatch(setMe(userInfo));
    queryClient.invalidateQueries({ queryKey: ['userInfo'] });
    return response;
  }
);

const updateUserInfoSlice = createSlice({
  name: 'updateUserInfo',
  initialState,
  reducers: {
    updateUserInfoReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserInfo.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      updateUserInfo.fulfilled,
      (state, action: PayloadAction<void>) => {
        state.loading = false;
        state.data = true;
      }
    );
    builder.addCase(updateUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default updateUserInfoSlice.reducer;
export const { updateUserInfoReset } = updateUserInfoSlice.actions;
