import { PackageDto } from 'src/core/models/dtos/package.dto';
import axios from 'src/core/services/axios';

export async function getPackagesUpgradeRequest(
  groupId?: number
): Promise<{ CUR: PackageDto[]; UPG: PackageDto[] }> {
  const response = await axios.get<{ CUR: PackageDto[]; UPG: PackageDto[] }>(
    `${process.env.REACT_APP_BASE_URL}/users/subscription/upgrade`,
    {
      params: {
        groupId,
      },
    }
  );
  return response.data;
}

export async function putUpgradeSub({
  fromUserSubId,
  toSubId,
}: {
  fromUserSubId: number;
  toSubId: number;
}) {
  const response = await axios.put<void>(
    `${process.env.REACT_APP_BASE_URL}/payment/sub`,
    {
      fromUserSubId,
      toSubId,
    }
  );
  return response.data;
}
