import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import FirebaseService from './firebaseService';

export enum FirebaseEvents {
  'login_view_user_count' = 'login_view_user_count',
  'login_sendcode_user_count' = 'login_sendcode_user_count',
  'login_otp_view_user_count' = 'login_otp_view_user_count',
  'login_otp_correct_otp_user_count' = 'login_otp_correct_otp_user_count',
  'login_otp_wrong_otp_user_count' = 'login_otp_wrong_otp_user_count',
  'signup_team_view_user_count' = 'signup_team_view_user_count',
  'signup_team_subscribe_user_count' = 'signup_team_subscribe_user_count',
  'homepage_view_user_count' = 'homepage_view_user_count',
  'homepage_purchase_user_count' = 'homepage_purchase_user_count',
  'homepage_convert_user_count' = 'homepage_convert_user_count',
  'homepage_wheel_widget_user_count' = 'homepage_wheel_widget_user_count',
  'homepage_trivia_user_count' = 'homepage_trivia_user_count',
  'subscribe_page_user_view_count' = 'subscribe_page_user_view_count',
  'cardtype_weekly_user_count' = 'cardtype_weekly_user_count',
  'cardtype_monthly_user_count' = 'cardtype_monthly_user_count',
  'cardtype_annual_user_count' = 'cardtype_annual_user_count',
  'payment_success_view_user_count' = 'payment_success_view_user_count',
  'vpos_payment_fail_view_user_count' = 'vpos_payment_fail_view_user_count',
  'vpos_payment_fail_retry_user_count' = 'vpos_payment_fail_retry_user_count',
  'poll_view_user_count' = 'poll_view_user_count',
  'poll_result_continue_button_count' = 'poll_result_continue_button_count',
  'trivia_view_user_count' = 'trivia_view_user_count',
  'wheel_right_view_user_count' = 'wheel_right_view_user_count',
  'wheel_view_non_subscribe_user_count' = 'wheel_view_non_subscribe_user_count',
  'wheel_without_view_subscribe_user_count' = 'wheel_without_view_subscribe_user_count',
  'wheel_spin_user_count' = 'wheel_spin_user_count',
  'convert_view_user_count' = 'convert_view_user_count',
  'convert_point_freebyte_count' = 'convert_point_freebyte_count',
  'convert_popup_accept_button_count' = 'convert_popup_accept_button_count',
  'convert_popup_insufficient_balance_view_count' = 'convert_popup_insufficient_balance_view_count',
  'profile_edit_delete_my_account_user_count' = 'profile_edit_delete_my_account_user_count',
  'profile_edit_delete_my_account_accept_user_count' = 'profile_edit_delete_my_account_accept_user_count',
  'profile_currentplan_update_user_count' = 'profile_currentplan_update_user_count',
  'profile_cancel_subs_popup_accept_user_count' = 'profile_cancel_subs_popup_accept_user_count',
}

export const useFirebaseEventOnMount = (
  eventKey: FirebaseEvents,
  enabled?: boolean
) => {
  const [uniqueId] = useState(nanoid());
  const [sent, setSent] = useState(false);
  useEffect(() => {
    if (enabled && !sent) {
      FirebaseService.logEvent(eventKey);
      setSent(true);
    }
  }, [enabled, eventKey, sent, uniqueId]);
};

export const useFirebaseEvent = (eventKey: FirebaseEvents) => {
  const trigger = () => {
    FirebaseService.logEvent(eventKey);
  };
  return trigger;
};
