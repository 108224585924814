import Icon from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, Layout, Progress, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import groupIcon from 'src/assets/images/group-icon.svg';
import hamburgerMenuProfileBg from 'src/assets/images/hamburgerMenuProfileBg.png';
import logoutIcon from 'src/assets/images/important-icon.png';
import logo from 'src/assets/images/logo_white.png';
import tick from 'src/assets/images/menu/blue-tick-icon.svg';
import game from 'src/assets/images/menu/game2.png';
import { ReactComponent as MenuIcon } from 'src/assets/images/menu/hamburger-menu-icon-2.svg';
import HelpIcon from 'src/assets/images/menu/HelpIcon';
import LogoutIcon from 'src/assets/images/menu/LogoutIcon';
import MailIcon from 'src/assets/images/menu/MailIcon';
import poll from 'src/assets/images/menu/poll2.png';
import ShareIcon from 'src/assets/images/menu/ShareIcon';
import TeamIcon from 'src/assets/images/menu/TeamIcon';
import wheel from 'src/assets/images/menu/wheel2.png';
import mobileLogo from 'src/assets/images/mobile-logo.png';
import settingsIcon from 'src/assets/images/settings.svg';
import PointIcon from 'src/assets/svg/PointIcon';
import useScreenSize from 'src/core/hooks/useScreenSize';
import { setMe } from 'src/core/services/app/setMe/setMe.slice';
import { Errors } from 'src/core/services/errors';
import {
  FirebaseEvents,
  useFirebaseEvent,
} from 'src/core/services/firebase/eventHooks';
import FirebaseService from 'src/core/services/firebase/firebaseService';
import { useAppDispatch, useAppSelector } from 'src/core/services/hooks';
import { useActiveQuiz } from 'src/core/services/quiz/quizApis';
import { useUserInfo } from 'src/core/services/user/userApi';
import { cn } from 'src/core/utilities/cn';
import Constants from 'src/core/utilities/constants';
import Helpers from 'src/core/utilities/helpers';
import { router } from 'src/core/utilities/router';
import FirebaseKey from '../FirebaseKey/FirebaseKey';
import AreYouSureModal from '../Modals/AreYouSureModal/AreYouSureModal';
import './AppMenu.scss';
import LeftMenuItem from './LeftMenuItem/LeftMenuItem';
import { UpgradeBanner } from './UpgradeBanner';
export enum MenuType {
  game,
  wheel,
  profile,
  poll,
  qr,
  home,
  team,
  point,
  pointBoard,
  subscribe,
  announcements,
  benefits,
}

function AppMenu() {
  const pathname = useAppSelector((state) => state.app.setPathname.pathname);
  const windowSize = useScreenSize();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [selectedMenu, setSelectedMenu] = useState<MenuType>();
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (isMobileMenuVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isMobileMenuVisible]);

  useEffect(() => {
    switch (pathname) {
      case router.PROFILE:
        setSelectedMenu(MenuType.profile);
        break;
      case router.HOME:
        setSelectedMenu(MenuType.home);
        break;
      case router.SUBSCRIPTION:
        setSelectedMenu(MenuType.subscribe);
        break;
      case router.LEADERBOARD:
        setSelectedMenu(MenuType.pointBoard);
        break;
      case router.ANNOUNCEMENTS:
        setSelectedMenu(MenuType.announcements);
        break;
      case router.TRIVIA:
        setSelectedMenu(MenuType.game);
        break;
      case router.WHEEL:
        setSelectedMenu(MenuType.wheel);
        break;
      case router.PLUSPOINT:
        setSelectedMenu(MenuType.point);
        break;
      case router.BENEFITS:
        setSelectedMenu(MenuType.benefits);
        break;
      default:
        setSelectedMenu(undefined);
        break;
    }
    setIsMobileMenuVisible(false);
  }, [pathname]);

  const handleClickMenu = (type: MenuType) => {
    setSelectedMenu(type);
    setIsMobileMenuVisible(false);
  };

  const handleLogoutButton = () => {
    setIsLogoutModalVisible(true);
  };

  const handleLogout = () => {
    dispatch(setMe());
    queryClient.clear();
    navigate(router.LANDING);
  };

  return (
    <>
      {windowSize.width < 768 ? (
        <div id="app-menu" className="relative z-50">
          <Layout.Header className="flex items-center bg-black/70 px-4 shadow-mobileHeader backdrop-blur-md md:hidden">
            <Button
              className="mobile-header-btn"
              icon={<Icon component={MenuIcon} />}
              onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
            />
            <MobileHeader
              isMobileMenuVisible={isMobileMenuVisible}
              selectedMenu={selectedMenu}
            />
          </Layout.Header>
          {isMobileMenuVisible && (
            <div className="fixed z-10 flex h-[calc(100dvh-64px)] w-full flex-col overflow-y-auto bg-black/70 p-4 backdrop-blur-md">
              <MenuContent
                selectedMenu={selectedMenu}
                setIsMobileMenuVisible={setIsMobileMenuVisible}
                handleClickMenu={handleClickMenu}
                handleLogoutButton={handleLogoutButton}
              />
            </div>
          )}
        </div>
      ) : (
        <Layout.Sider
          id="app-menu"
          width={284}
          className="hidden font-medium md:block"
        >
          <div className="flex h-full flex-col">
            <div className="p-4 pb-6">
              <Link to={router.HOME}>
                <img className="w-36" src={logo} alt="logo" />
              </Link>
            </div>
            <MenuContent
              selectedMenu={selectedMenu}
              setIsMobileMenuVisible={setIsMobileMenuVisible}
              handleClickMenu={handleClickMenu}
              handleLogoutButton={handleLogoutButton}
            />
          </div>
        </Layout.Sider>
      )}
      <AreYouSureModal
        isVisible={isLogoutModalVisible}
        isError={true}
        titleKey={FirebaseService.logout_popup_title}
        descKey={FirebaseService.logout_popup_desc}
        iconSrc={logoutIcon}
        cancelTextKey={FirebaseService.logout_popup_cancel_button}
        confirmTextKey={FirebaseService.logout_popup_exit_button}
        handleCancel={() => setIsLogoutModalVisible(false)}
        handleConfirm={handleLogout}
      />
    </>
  );
}

function MobileHeader({
  isMobileMenuVisible,
  selectedMenu,
}: {
  isMobileMenuVisible: boolean;
  selectedMenu?: MenuType;
}) {
  const sendWheelEvent = useFirebaseEvent(
    FirebaseEvents.homepage_wheel_widget_user_count
  );
  const sendTriviaEvent = useFirebaseEvent(
    FirebaseEvents.homepage_trivia_user_count
  );
  const { error: activeQuizError } = useActiveQuiz();
  const isNoMoreQuiz =
    (activeQuizError && Errors.isNoMoreQuizError(activeQuizError)) || false;
  const isNoActiveQuiz =
    (activeQuizError && Errors.isNoActiveQuizError(activeQuizError)) || false;
  const navigate = useNavigate();
  const { data: me } = useUserInfo();

  if (isMobileMenuVisible) {
    return (
      <div className="flex flex-grow justify-center pr-10">
        <Link to={router.HOME}>
          <img className="w-44" src={mobileLogo} alt="logo" />
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-grow items-center justify-end gap-2">
      <Button
        disabled={isNoMoreQuiz}
        onClick={() => navigate(router.POLL)}
        className="mobile-header-btn relative"
      >
        {isNoActiveQuiz && (
          <div className="absolute right-0.5 top-0.5 aspect-square w-2.5 rounded-full bg-[#E5484D]"></div>
        )}
        <img className="h-5" src={poll} alt="wheel" />
      </Button>
      <Button
        onClick={() => {
          sendTriviaEvent();
          navigate(router.TRIVIA);
        }}
        className="mobile-header-btn"
      >
        <img className="h-5" src={game} alt="game" />
      </Button>
      <Button
        onClick={() => {
          sendWheelEvent();
          navigate(router.WHEEL);
        }}
        className="mobile-header-btn"
      >
        <img className="h-5" src={wheel} alt="wheel" />
      </Button>
      <Button
        className={`mobile-header-btn ml-2 p-1 pb-0 ${
          selectedMenu === MenuType.profile ? 'border-green' : ''
        }`}
        onClick={() => navigate(router.PROFILE)}
      >
        <img
          className="max-w-full rounded-md"
          src={me?.avatarImageUrl ? me.avatarImageUrl : Constants.defaultAvatar}
          alt="avatar"
        />
      </Button>
    </div>
  );
}

function MenuContent({
  selectedMenu,
  setIsMobileMenuVisible,
  handleClickMenu,
  handleLogoutButton,
}: {
  selectedMenu?: MenuType;
  setIsMobileMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickMenu: any;
  handleLogoutButton: any;
}) {
  const sendConvertEvent = useFirebaseEvent(
    FirebaseEvents.homepage_convert_user_count
  );
  const sendWheelEvent = useFirebaseEvent(
    FirebaseEvents.homepage_wheel_widget_user_count
  );
  const sendTriviaEvent = useFirebaseEvent(
    FirebaseEvents.homepage_trivia_user_count
  );
  const { error: activeQuizError } = useActiveQuiz();
  const isNoMoreQuiz =
    (activeQuizError && Errors.isNoMoreQuizError(activeQuizError)) || false;
  const isNoActiveQuiz =
    (activeQuizError && Errors.isNoActiveQuizError(activeQuizError)) || false;
  const navigate = useNavigate();
  const { data: userInfo } = useUserInfo();
  function menuClassNames(type: MenuType) {
    return cn(
      `p-2 rounded-md break-words text-center whitespace-normal h-full`,
      type === selectedMenu && 'border-green'
    );
  }

  if (!userInfo) {
    return null;
  }

  const { avatarImageUrl, activeSubs, points, team, tribuneGroup } = userInfo;

  const isSubscribe = activeSubs && activeSubs.length > 0;

  return (
    <div className="flex flex-grow flex-col gap-6 text-white">
      <div className="flex flex-grow flex-col gap-3">
        <div
          className="relative mt-7 flex cursor-pointer items-end gap-3 rounded-2xl"
          onClick={() => {
            navigate(router.PROFILE);
            setIsMobileMenuVisible(false);
          }}
        >
          <img src={hamburgerMenuProfileBg} alt="" className="absolute" />
          <img
            className="-mt-7 w-20"
            src={avatarImageUrl ? avatarImageUrl : Constants.defaultAvatar}
            alt="user"
          />
          <div className="flex w-full justify-between gap-1 p-0">
            <div className="flex flex-col items-start justify-evenly">
              <div className="mb-2 flex items-center gap-1 text-base">
                {isSubscribe && <img src={tick} alt="tick" />}
                <span className="max-w-[12ch] overflow-x-clip overflow-ellipsis">
                  {Helpers.getUserName(userInfo, true)}
                </span>
              </div>
              {/* Subscription Info */}
              <div className="space-y-1">
                <div className="flex items-center gap-1">
                  <TeamIcon />
                  <span>{team?.name}</span>
                </div>
                {tribuneGroup ? (
                  <div className="flex items-center gap-1">
                    <img src={groupIcon} className="h-4 w-4 p-0.5" alt="" />
                    {tribuneGroup}
                  </div>
                ) : (
                  <Link
                    to={'/profile#edit-profile'}
                    className="flex items-center gap-1"
                    onClick={(e) => {
                      //do not trigger parent's onClick
                      e.stopPropagation();
                    }}
                  >
                    <img src={groupIcon} className="h-4 w-4 p-0.5" alt="" />
                    <FirebaseKey
                      firebaseKey={FirebaseService.menu_choise_group}
                    />
                  </Link>
                )}
              </div>
            </div>
            <div className="ml-auto flex flex-col items-center gap-1">
              <div>
                <Progress
                  type="circle"
                  className="text-white [&_.ant-progress-circle-path]:stroke-[#0DC8FF88] [&_span]:text-white"
                  size={45}
                  percent={userInfo?.progress || 0}
                />
              </div>
              <Link to="/profile">
                <img
                  className="-m-2 h-12 w-12"
                  src={settingsIcon}
                  alt="point"
                />
              </Link>
            </div>
          </div>
        </div>
        <div
          className="mb-4 flex cursor-pointer items-center gap-1 rounded-lg border border-solid border-primary/30 bg-black-dark/50 py-1 pl-1 pr-1 text-primary"
          onClick={() => {
            sendConvertEvent();
            navigate(router.PLUSPOINT);
            setIsMobileMenuVisible(false);
          }}
        >
          <PointIcon />
          <div className="grow text-base font-bold leading-5 text-white">
            {Helpers.convertNumberFormat(points)}
          </div>
          <Button
            size="small"
            className="max-w-40 overflow-hidden border-none bg-[#2CFF091D] text-[#2CFF09]"
          >
            <FirebaseKey
              firebaseKey={FirebaseService.hamburger_puan_kullan_buton}
            />
          </Button>
        </div>
        <div className="mb-6">
          <UpgradeBanner />
        </div>
        <Row gutter={12} className="mb-6 text-white">
          <Col span={8}>
            <Button
              block
              type="link"
              disabled={isNoMoreQuiz}
              className={cn(
                menuClassNames(MenuType.poll),
                'relative',
                isNoMoreQuiz
                  ? 'border-[#3F3F3F] bg-white/5 text-white/30'
                  : 'border border-[#005476] bg-[#0091F714] text-white'
              )}
              onClick={() => {
                navigate(router.POLL);
                handleClickMenu(MenuType.poll);
              }}
            >
              {isNoActiveQuiz && (
                <div className="absolute right-2 top-2 aspect-square w-2.5 rounded-full bg-[#E5484D]" />
              )}
              <img className="h-8" src={poll} alt="game" />
              <div className="font-medium">
                <FirebaseKey
                  firebaseKey={FirebaseService.hamburger_anket_buton}
                />
              </div>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              block
              type="link"
              className={cn(
                menuClassNames(MenuType.game),
                'border border-[#005476] bg-[#0091F714] text-white'
              )}
              onClick={() => {
                sendTriviaEvent();
                navigate(router.TRIVIA);
                handleClickMenu(MenuType.game);
              }}
            >
              <img className="h-8" src={game} alt="game" />
              <div className="font-medium">
                <FirebaseKey
                  firebaseKey={FirebaseService.hamburger_oyun_buton}
                />
              </div>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              block
              type="link"
              className={cn(
                menuClassNames(MenuType.wheel),
                `border border-[#005476] bg-[#0091F714] text-white`
              )}
              onClick={() => {
                sendWheelEvent();
                navigate(router.WHEEL);
                handleClickMenu(MenuType.wheel);
              }}
            >
              <img className="h-8" src={wheel} alt="wheel" />
              <div className="font-medium">
                <FirebaseKey
                  firebaseKey={FirebaseService.hamburger_cark_buton}
                />
              </div>
            </Button>
          </Col>
        </Row>
        <LeftMenuItem
          selectedMenu={selectedMenu}
          menuType={MenuType.home}
          link={router.HOME}
          handleClickMenu={handleClickMenu}
        />
        <LeftMenuItem
          selectedMenu={selectedMenu}
          menuType={MenuType.pointBoard}
          link={router.LEADERBOARD}
          handleClickMenu={handleClickMenu}
        />
        <LeftMenuItem
          selectedMenu={selectedMenu}
          menuType={MenuType.benefits}
          link={router.BENEFITS}
          handleClickMenu={handleClickMenu}
        />
        <LeftMenuItem
          selectedMenu={selectedMenu}
          menuType={MenuType.announcements}
          link={router.ANNOUNCEMENTS}
          handleClickMenu={handleClickMenu}
        />
      </div>
      <div className="flex items-center justify-between gap-3">
        <div className="flex items-center gap-3">
          <Button
            type="link"
            className="h-10 w-10 p-0"
            icon={<HelpIcon />}
            onClick={() => navigate(router.FAQ)}
          />
          <Button
            type="link"
            className="h-10 w-10 p-0"
            icon={<MailIcon />}
            onClick={() => navigate(router.CONTACTUS)}
          />
          <Button
            type="link"
            className="h-10 w-10 p-0"
            icon={<ShareIcon />}
            onClick={() => navigate(router.INVITE)}
          />
        </div>
        <Button
          type="link"
          className="h-10 w-10 p-0"
          icon={<LogoutIcon />}
          onClick={handleLogoutButton}
        />
      </div>
    </div>
  );
}
export default AppMenu;
