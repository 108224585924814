import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUserInfo } from 'src/core/services/user/userApi';
import hamburger from '../../assets/images/hamburgerLanding.png';
import logo from '../../assets/images/logo_white.png';
import mobileLogo from '../../assets/images/mobile-logo.png';
import useScroll from '../../core/hooks/useScroll';
import FirebaseService from '../../core/services/firebase/firebaseService';
import { router } from '../../core/utilities/router';
import FirebaseKey from '../FirebaseKey/FirebaseKey';

const Header = () => {
  const { data: me } = useUserInfo();
  const scrolled = useScroll(70);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <header
      className={`sticky z-40 top-0 max-md:backdrop-blur-sm ${
        !open ? 'bg-black/70' : 'none'
      }  max-md:py-2 py-4 w-full ${scrolled && 'backdrop-blur-sm  '}`}
    >
      <div className="grid ">
        <div className="flex justify-between items-center px-16 max-md:px-1 max-md:pr-11  z-40 ">
          <img
            onClick={() => setOpen(!open)}
            className="cursor-pointer md:hidden"
            src={hamburger}
            alt="..."
          />
          <Link to={router.LANDING}>
            <img src={logo} className="max-md:hidden h-12" alt="..." />
            <img src={mobileLogo} className="md:hidden w-44" alt="..." />
          </Link>
          <div className="flex items-center backdrop-blur-md  max-md:hidden py-4 px-6 rounded-full  bg-white/5  gap-x-8">
            <Link
              className="text-white text-base font-medium tracking-wider no-underline"
              to={me ? router.HOME : router.LANDING}
            >
              <FirebaseKey firebaseKey={FirebaseService.header_homepage_tab} />
            </Link>
            <Link
              className="text-white text-base font-medium tracking-wider no-underline"
              to={router.CONTACTUS}
            >
              <FirebaseKey firebaseKey={FirebaseService.header_contact_tab} />
            </Link>
            <Link
              className="text-white text-base font-medium tracking-wider no-underline"
              to={router.FAQ}
            >
              <FirebaseKey firebaseKey={FirebaseService.header_faq_tab} />
            </Link>
          </div>
          <div className="flex gap-x-2 max-md:hidden w-32">
            {!me?.id && (
              <Button
                className="h-10 rounded-2xl bg-white/5 backdrop-blur-md border-0 text-base text-white font-medium"
                onClick={() => {
                  navigate(router.LOGIN);
                  setOpen(false);
                }}
              >
                <FirebaseKey
                  firebaseKey={FirebaseService.header_login_button}
                />
              </Button>
            )}
          </div>
          <p className="cursor-pointer md:hidden"></p>
        </div>
        {open && (
          <div className="absolute w-full h-screen z-20 -mt-2 backdrop-blur-lg max-md:bg-black/70">
            <div className="flex flex-col items-center gap-8 mt-32">
              <Link
                className="text-white text-2xl font-medium tracking-wider"
                to={me ? router.HOME : router.LANDING}
              >
                <FirebaseKey
                  firebaseKey={FirebaseService.header_homepage_tab}
                />
              </Link>
              <Link
                className="text-white text-2xl font-medium tracking-wider"
                to={router.CONTACTUS}
              >
                <FirebaseKey firebaseKey={FirebaseService.header_contact_tab} />
              </Link>
              <Link
                className="text-white text-2xl font-medium tracking-wider"
                to={router.FAQ}
              >
                <FirebaseKey firebaseKey={FirebaseService.header_faq_tab} />
              </Link>
            </div>
            <div className="mt-20 justify-center items-center grid gap-y-2">
              {!me?.id && (
                <Button
                  className="h-11 text-base rounded-lg backdrop-blur-md bg-white/5 border-0 text-white"
                  onClick={() => {
                    navigate(router.LOGIN);
                    setOpen(false);
                  }}
                >
                  <FirebaseKey
                    firebaseKey={FirebaseService.header_login_button}
                  />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
