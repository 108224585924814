import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { UI } from '../../../core/utilities/ui';
import FirebaseKey from '../../FirebaseKey/FirebaseKey';

interface IProps {
  isVisible: boolean;
  isError: boolean;
  titleKey: string;
  descKey: string;
  iconSrc: string;
  cancelTextKey: string;
  confirmTextKey: string;
  handleCancel: () => void;
  handleConfirm: () => void;
  debounce?: boolean;
}

function AreYouSureModal(props: IProps) {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (buttonDisabled) {
        setButtonDisabled(false);
      }
    }, 2000);
  }, [buttonDisabled]);

  return (
    <Modal
      className="profile-modal"
      open={props.isVisible}
      destroyOnClose
      footer={null}
      onCancel={props.handleCancel}
      closable={false}
      width={373}
      styles={{
        mask: UI.profileModalMaskStyle(),
      }}
    >
      <div
        className={`section-header pt-0 -mr-4 md:-mr-8 ${
          props.isError ? 'border-red' : ''
        }`}
      >
        <h2 className="text-base font-medium text-white m-0">
          <FirebaseKey firebaseKey={props.titleKey} />
        </h2>
      </div>
      <div className="mt-3">
        <div className="flex items-center gap-2 text-white">
          {props.iconSrc && (
            <img
              className="w-8 h-8 object-contain"
              src={props.iconSrc}
              alt="icon"
            />
          )}
          <FirebaseKey firebaseKey={props.descKey} />
        </div>
        <div className="flex gap-4 mt-6">
          {props.cancelTextKey && (
            <Button
              block
              className={`rounded-xl border-0 font-medium ${
                props.isError
                  ? 'bg-green/5 text-primary'
                  : 'bg-white/5 text-red'
              }`}
              onClick={props.handleCancel}
            >
              <FirebaseKey firebaseKey={props.cancelTextKey} />
            </Button>
          )}
          <Button
            block
            className={twMerge(
              `rounded-xl border-0 font-medium`,
              props.isError ? 'bg-white/5 text-red' : 'bg-green/5 text-primary',
              buttonDisabled ? 'opacity-50' : ''
            )}
            onClick={() => {
              props.handleConfirm();
              props.debounce && setButtonDisabled(true);
            }}
            disabled={buttonDisabled}
          >
            <FirebaseKey firebaseKey={props.confirmTextKey} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AreYouSureModal;
