import { Location } from 'react-router-dom';
import { CommentDto } from '../models/dtos/commentList.dto';
import { LeaderboardDto } from '../models/dtos/leaderboard.dto';
import { MeDto } from '../models/dtos/me.dto';
import Constants from './constants';

export default class Helpers {
  static isEnvProd = (): boolean => {
    const env = `${process.env.REACT_APP_ENV}`;
    return env === 'production';
  };

  static wait = (ms = 500) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  static convertToJsonValidString(objString: any) {
    let correctedString = objString.replace(/'/g, '"');
    correctedString = correctedString.replace(/(\w+):/g, '"$1":');
    return correctedString;
  }

  static getQueryParam = (
    location: Location,
    query: string
  ): string | undefined => {
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    return searchParams.get(query) ?? undefined;
  };

  static isMsisdnTurkish(msisdn: string): boolean {
    if (msisdn) {
      if (msisdn.length >= 2) {
        return msisdn.slice(0, 2) === '90';
      }
    }
    return false;
  }

  static parseBannedSec(message?: string): number {
    const bannedSec = message?.split(Constants.bannedSeparator)[2] || '0';
    return +bannedSec;
  }

  static getMonthName(monthNumber: number): string {
    switch (monthNumber) {
      case 0:
        return 'Ocak';
      case 1:
        return 'Şubat';
      case 2:
        return 'Mart';
      case 3:
        return 'Nisan';
      case 4:
        return 'Mayıs';
      case 5:
        return 'Haziran';
      case 6:
        return 'Temmuz';
      case 7:
        return 'Ağustos';
      case 8:
        return 'Eylül';
      case 9:
        return 'Ekim';
      case 10:
        return 'Kasım';
      default:
        return 'Aralık';
    }
  }

  public static clearNumber(value = ''): string {
    return value.replace(/\D+/g, '');
  }

  static isTcknValid(tckn: string): boolean {
    if (!tckn || tckn.length !== 11) {
      return false;
    } else {
      // 1-3-5-7-9. haneler toplamından, 2-4-6-8. haneleri çıkar.
      // 10'a böl, kalan sayı 10. haneyi verecek
      let odds = 0;
      let evens = 0;

      for (let i = 0; i <= 8; i += 2) odds += Number.parseInt(tckn[i]);

      for (let i = 1; i <= 7; i += 2) evens += Number.parseInt(tckn[i]);

      if ((7 * odds - evens) % 10 !== Number.parseInt(tckn[9])) return false;

      // 1-10. haneler toplamının 10'a bölümünden kalan, 11. haneyi verecek
      let total = 0;
      for (let i = 0; i <= 9; i++) {
        total += Number.parseInt(tckn[i]);
      }
      return total % 10 === Number.parseInt(tckn[10]);
    }
  }

  public static formatCardNumber(
    value: string,
    seperator: string = '-'
  ): string {
    if (!value) {
      return value;
    }
    const clearValue = this.clearNumber(value);
    const nextValue = `${clearValue.slice(0, 4)}${seperator}${clearValue.slice(
      4,
      8
    )}${seperator}${clearValue.slice(8, 12)}${seperator}${clearValue.slice(
      12,
      16
    )}`;
    return nextValue.trim();
  }

  public static dropdownSearchFilterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    (option?.label ?? '')
      .toLocaleLowerCase('tr')
      .includes(input.toLocaleLowerCase('tr'));

  public static getLetterFromIndex(index: number): string {
    switch (index) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      case 4:
        return 'E';
      case 5:
        return 'F';
      case 6:
        return 'G';
      case 7:
        return 'H';
      case 8:
        return 'I';
      default:
        return '';
    }
  }

  public static getUserName = (
    user?: MeDto | LeaderboardDto | CommentDto,
    isFullName?: boolean
  ): string => {
    return user?.nickname
      ? user.nickname
      : user?.name
      ? `${user?.name} ${
          isFullName
            ? user?.surname
            : user?.surname
            ? `${user.surname.charAt(0)}.`
            : ''
        }`
      : '';
  };

  public static convertNumberFormat = (number?: number): string => {
    const formattedNumber = new Intl.NumberFormat('tr-TR', {
      maximumFractionDigits: 3,
    }).format(number || 0);
    return formattedNumber.replace(/\./g, '.');
  };

  public static convertPriceFormat = (price: number = 0): string => {
    const mainPrice = Math.floor(price / 100);
    const remaining = price % 100;
    let remain = '';
    if (remaining) {
      remain = ',' + ('0' + remaining).slice(-2);
    }
    return Helpers.convertNumberFormat(mainPrice) + remain;
  };
}
