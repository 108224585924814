import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import getPackagesRequest from './getPackages/getPackages.repository';
import {
  getPackagesUpgradeRequest,
  putUpgradeSub,
} from './getPackagesUpgrade/getPackagesUpgrade.repository';
import getCampaignSubRequest from './getUserCampaignSub/getUserCampaignSub.repository';
import getUserInfoRequest from './getUserInfo/getUserInfo.repository';

export const usePackagesUpgrade = (groupId?: number, enabled = true) => {
  return useQuery({
    queryKey: ['packagesUpgrade', groupId],
    queryFn: () => getPackagesUpgradeRequest(groupId),
    enabled,
  });
};

export const usePackages = (groupId?: number, enabled = true) => {
  return useQuery({
    queryKey: ['packages', groupId],
    queryFn: () => getPackagesRequest(groupId),
    enabled,
  });
};
export const useUpgradeSubscription = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['upgradeSubscription'],
    mutationFn: ({
      fromUserSubId,
      toSubId,
    }: {
      fromUserSubId: number;
      toSubId: number;
    }) => putUpgradeSub({ fromUserSubId, toSubId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userInfo'] });
      onSuccess?.();
    },
  });
};

export const useUserInfo = (enabled = true) => {
  return useQuery({
    queryKey: ['userInfo'],
    queryFn: () => getUserInfoRequest(),
    enabled,
    retry: false,
    placeholderData: keepPreviousData,
  });
};

export const useUserCampaignSub = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['userCampaignSub'],
    mutationFn: (code: string) => getCampaignSubRequest({ code }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userInfo'] });
    },
  });
};
