import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from 'src';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { MeDto } from '../../../models/dtos/me.dto';
import { setMe } from '../../app/setMe/setMe.slice';
import { Errors } from '../../errors';
import getUserInfoRequest from './getUserInfo.repository';

type InitialState = {
  loading: boolean;
  data?: MeDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getUserInfo = createAsyncThunk(
  'getUserInfo',
  async (_, { dispatch }) => {
    const response = await getUserInfoRequest();
    if (response.nickname && response.team) {
      dispatch(setMe(response));
      queryClient.invalidateQueries({ queryKey: ['userInfo'] });
    }
    return response;
  }
);

const getUserInfoSlice = createSlice({
  name: 'getUserInfo',
  initialState,
  reducers: {
    getUserInfoReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true;
      // state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getUserInfo.fulfilled,
      (state, action: PayloadAction<MeDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getUserInfoSlice.reducer;
export const { getUserInfoReset } = getUserInfoSlice.actions;
